import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isAfter,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
} from 'date-fns';
import { useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface BookedDate {
  date: string;
  styleId: number;
}

interface CalendarProps {
  onChange?: (date: Date | Date[]) => void;
  selectRange?: boolean;
  selectedDates?: string[];
  availableDays?: string[] | null;
  minDate?: Date;
  bookedDates?: BookedDate[];
  styleId?: number;
  value?: Date[];  // Add this
  tileClassName?: (args: { date: Date }) => string;
}

const Calendar: React.FC<CalendarProps> = ({
  onChange,
  selectRange,
  selectedDates = [],
  availableDays = null, // Default value is null
  minDate,
  bookedDates = [],
  styleId,
  value,  // Add this
  tileClassName,

}) => {
  let today = startOfToday();
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function handleDayClick(day: Date) {
    if (selectRange) {
      const selectedDatesAsDate = selectedDates.map((date) =>
        parse(date, 'yyyy-MM-dd', new Date())
      );
      if (
        selectedDatesAsDate.some((selectedDate) => isSameDay(selectedDate, day))
      ) {
        onChange(
          selectedDatesAsDate.filter(
            (selectedDate) => !isSameDay(selectedDate, day)
          )
        );
      } else {
        onChange([...selectedDatesAsDate, day]);
      }
    } else {
      onChange(day);
    }
  }

  const isPreviousMonthDisabled = () => {
    const firstDayOfPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
    return isAfter(minDate, endOfMonth(firstDayOfPreviousMonth));
  };

  const isDateBooked = (date: string) => {
    return bookedDates.some((bookedDate) => bookedDate.date === date);
  };

  const availableDaysArray = availableDays || [];

  return (
    <div className="mx-auto px-2 py-10 gap-4">
      <div className="bg-white rounded-lg shadow-lg max-w-lg ">
        <div className="px-4 py-4 md:px-6 md:py-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg md:text-xl font-bold text-gray-800">
              {format(firstDayCurrentMonth, 'MMMM yyyy')}
            </h2>
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={previousMonth}
                disabled={isPreviousMonthDisabled()}
                className={classNames(
                  'p-2 text-gray-600 hover:text-gray-800 focus:outline-none',
                  isPreviousMonthDisabled() && 'opacity-50 cursor-not-allowed'
                )}
              >
                <FaChevronLeft
                  className="w-4 h-4 md:w-5 md:h-5"
                  aria-hidden="true"
                />
              </button>
              <button
                onClick={nextMonth}
                type="button"
                className="p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                <FaChevronRight
                  className="w-4 h-4 md:w-5 md:h-5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-2 md:gap-4 text-center">
            <div className="text-gray-500 text-xs md:text-sm">Sun</div>
            <div className="text-gray-500 text-xs md:text-sm">Mon</div>
            <div className="text-gray-500 text-xs md:text-sm">Tue</div>
            <div className="text-gray-500 text-xs md:text-sm">Wed</div>
            <div className="text-gray-500 text-xs md:text-sm">Thu</div>
            <div className="text-gray-500 text-xs md:text-sm">Fri</div>
            <div className="text-gray-500 text-xs md:text-sm">Sat</div>
            {days.map((day, dayIdx) => (
              <div
                key={day.toString()}
                className={classNames(
                  dayIdx === 0 && colStartClasses[getDay(day)],
                  'py-1 md:py-2'
                )}
              >
                <button
                  type="button"
                  onClick={() => handleDayClick(day)}
                  disabled={
                    isAfter(minDate, day) ||
                    isDateBooked(format(day, 'yyyy-MM-dd')) ||
                    (availableDays &&
                      !availableDaysArray.includes(format(day, 'yyyy-MM-dd')))
                  }
                  className={classNames(
                    selectedDates.includes(format(day, 'yyyy-MM-dd'))
                      ? 'bg-purple-500 text-white'
                      : isDateBooked(format(day, 'yyyy-MM-dd'))
                      ? 'bg-red-500 text-white cursor-not-allowed'
                      : availableDaysArray.includes(format(day, 'yyyy-MM-dd'))
                      ? 'bg-pink-500 text-white'
                      : 'text-gray-400 cursor-not-allowed',
                    !selectedDates.includes(format(day, 'yyyy-MM-dd')) &&
                      isToday(day) &&
                      'text-pink-500 font-bold',
                    !selectedDates.includes(format(day, 'yyyy-MM-dd')) &&
                      !isToday(day) &&
                      !isSameMonth(day, firstDayCurrentMonth) &&
                      'text-gray-400',
                    isAfter(minDate, day) && 'opacity-50 cursor-not-allowed',
                    'w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-blue-100 focus:outline-none text-xs md:text-sm'
                  )}
                >
                  <time dateTime={format(day, 'yyyy-MM-dd')}>
                    {format(day, 'd')}
                  </time>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

let colStartClasses = [
  'col-start-1',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
];

export default Calendar;
