/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import 'react-calendar/dist/Calendar.css';

import { RootState } from '../../redux/store/store';
import { fetchServiceTypes } from '../../redux/reducers/serviceTypeSlice';
import { format } from 'date-fns';
import Calendar from '../../components/Calendar/Calendar';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';

interface ServiceInfoProps {}

const ServiceInfo: React.FC<ServiceInfoProps> = () => {
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.user.userData?.token);

  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
  const services = useSelector(
    (state: RootState) => state.serviceTypes.services
  );

  const [selectedService, setSelectedService] = useState(0);
  const [selectedServiceName, setSelectedServiceName] = useState('');
  const [styles, setStyles] = useState('');
  const [deposit, setDeposit] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(servicePrice);
  const [shortDescription, setShortDescription] = useState('');
  const [styleDuration, setStyleDuration] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fullDetails, setFullDetails] = useState('');
  const maxLength = 255;
  const maxlenghtShort = 20;
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [dateTimeMapping, setDateTimeMapping] = useState<{
    [date: string]: string[];
  }>({});
  const [selectedHour, setSelectedHour] = useState('00');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop: (acceptedFiles) => {
      setUploadedFile(acceptedFiles[0]);
    },
  });

 useEffect(() => {
    dispatch(fetchServiceTypes());
  }, [dispatch]);


  const handleDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const filteredDates = date.filter((d) => d >= new Date());
      const formattedDates = filteredDates.map((d) => format(d, 'yyyy-MM-dd'));
      setSelectedDates(formattedDates);

      // Maintain existing time mappings for selected dates
      const newMapping = { ...dateTimeMapping };
      formattedDates.forEach(date => {
        if (!newMapping[date]) {
          newMapping[date] = [];
        }
      });

      // Remove mappings for unselected dates
      Object.keys(newMapping).forEach(date => {
        if (!formattedDates.includes(date)) {
          delete newMapping[date];
        }
      });

      setDateTimeMapping(newMapping);
    } else {
      if (date < new Date()) {
        toast.error('Cannot select previous dates');
      } else {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setSelectedDates([formattedDate]);
        setDateTimeMapping({ [formattedDate]: [] });
      }
    }
  };


  const handleShortDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value;
    if (input.length <= maxlenghtShort) {
      setShortDescription(input);
    }
  };


  const handleFullDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value;
    if (input.length <= maxLength) {
      setFullDetails(input);
    }
  };

  const updateTimeSlots = (duration: string) => {
    const [hours, minutes] = duration.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;

    const startTime = 8 * 60; // Start at 8:00 AM
    const endTime = 19 * 60; // End at 7:00 PM

    const newTimeSlots = [];

    for (let currentTime = startTime; currentTime + totalMinutes <= endTime; currentTime += totalMinutes) {
      const hour = Math.floor(currentTime / 60);
      const minute = currentTime % 60;
      newTimeSlots.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
    }

    setAvailableTimes(newTimeSlots);
  };


  const updateAvailableTimeSlots = (duration: string) => {
    const [hours, minutes] = duration.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) return;

    const durationInMinutes = hours * 60 + minutes;
    const slots = [];
    let currentTime = new Date(2024, 0, 1, 8, 0);
    const endTime = new Date(2024, 0, 1, 19, 0);

    while (currentTime < endTime) {
      slots.push(currentTime.toTimeString().slice(0, 5));
      currentTime.setMinutes(currentTime.getMinutes() + durationInMinutes);
    }

    setAvailableTimeSlots(slots);
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setStyleDuration(input);

    if (/^[1-9]:[0-5][0-9]$/.test(input)) {
      updateAvailableTimeSlots(input);
    }
  };


  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedHour(e.target.value);
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMinute(e.target.value);
  };

  const handleAddTime = (selectedDate: string) => {
    const selectedTime = `${selectedHour}:${selectedMinute}`;

    if (!availableTimeSlots.includes(selectedTime)) {
      toast.error('Please select a valid time slot based on the style duration.');
      return;
    }

    if (dateTimeMapping[selectedDate]?.includes(selectedTime)) {
      toast.error('This time slot has already been added for this date.');
      return;
    }

    setDateTimeMapping(prev => ({
      ...prev,
      [selectedDate]: [...(prev[selectedDate] || []), selectedTime].sort()
    }));
  };

  const handleRemoveTime = (date: string, time: string) => {
    setDateTimeMapping(prev => ({
      ...prev,
      [date]: prev[date].filter(t => t !== time)
    }));
  };

  const renderTimeOptions = () => {
    return availableTimes.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };


  const renderHourOptions = () => {
    const hourOptions = [];

    for (let hour = 8; hour < 19; hour++) {
      const formattedHour = String(hour).padStart(2, '0');
      hourOptions.push(
        <option key={formattedHour} value={formattedHour}>
          {formattedHour}
        </option>
      );
    }

    return hourOptions;
  };

  const renderMinuteOptions = () => {
    const minuteOptions = [];

    for (let minute = 0; minute < 60; minute++) {
      const formattedMinute = String(minute).padStart(2, '0');
      minuteOptions.push(
        <option key={formattedMinute} value={formattedMinute}>
          {formattedMinute}
        </option>
      );
    }

    return minuteOptions;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('serviceId', String(selectedService));
    formData.append('serviceName', selectedServiceName);
    formData.append('style', styles);
    formData.append('styleShortDescription', shortDescription);
    formData.append('styleFullDetailsOfService', fullDetails);
    formData.append('stylePrice', servicePrice);
    if (uploadedFile) {
      formData.append('styleImage', uploadedFile);
    }
    formData.append('styleDuration', styleDuration);
    formData.append('styleDeposit', deposit);
    formData.append('discount', discount);
    formData.append('value', discountedPrice);

    // Format the dates and times for submission
    const availableDates = Object.keys(dateTimeMapping);
    const formattedTimes = availableDates.map(date =>
      dateTimeMapping[date].join(',')
    );

    formData.append('availableDays', availableDates.join(','));
    formData.append('availableTimes', formattedTimes.join('|'));

    try {
      const response = await api.post(
        `/api/styles/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to add style');
      }

      toast.success('Style added successfully!');
      resetForm();
      navigate('/admin/services');
    } catch (err) {
      toast.error('Failed to add style. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedService(0);
    setSelectedServiceName('');
    setStyles('');
    setShortDescription('');
    setFullDetails('');
    setServicePrice('');
    setDeposit('');
    setDiscount('');
    setDiscountedPrice('');
    setStyleDuration('');
    setUploadedFile(null);
    setSelectedDates([]);
    setDateTimeMapping({});
    setSelectedHour('00');
    setSelectedMinute('00');
  };

  const calculateDiscountedPrice = () => {
    const price = parseFloat(servicePrice);
    const discountPercentage = parseFloat(discount);
    if (!isNaN(price) && !isNaN(discountPercentage)) {
      const discountAmount = (price * discountPercentage) / 100;
      const discountedPrice = price - discountAmount;
      setDiscountedPrice(discountedPrice.toString());
    }
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 py-8">
    
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <h2 className="text-2xl font-semibold text-brand-primary mb-8">
            Add Style
          </h2>

          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Main Grid */}
            <div className="grid md:grid-cols-3 gap-8">
              {/* First Column - Basic Info */}
              <div className="bg-white rounded-lg shadow-lg p-6">
                <div className="space-y-6">
                  {/* Service Name */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                      Service Name
                    </label>
                    <select
                      value={selectedService}
                      onChange={(e) => {
                        const serviceId = parseInt(e.target.value, 10);
                        setSelectedService(serviceId);
                        const service = services.find(
                          (service) => service.serviceId === serviceId
                        );
                        setSelectedServiceName(service ? service.serviceName : '');
                      }}
                      className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                    >
                      <option value="">Select a service</option>
                      {services.map((service) => (
                        <option key={service.serviceId} value={service.serviceId}>
                          {service.serviceName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Style Name */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                      Style Name
                    </label>
                    <input
                      type="text"
                      value={styles}
                      onChange={(e) => setStyles(e.target.value)}
                      className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                      placeholder="Medium"
                    />
                  </div>

                  {/* Price Group */}
                  <div className="grid grid-cols-2 gap-4">
                    {/* Deposit */}
                    <div>
                      <label className="block mb-2 text-sm font-medium text-brand-primary">
                        Deposit
                      </label>
                      <input
                        type="text"
                        value={deposit}
                        onChange={(e) => setDeposit(e.target.value)}
                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                        placeholder="20"
                      />
                    </div>

                    {/* Service Price */}
                    <div>
                      <label className="block mb-2 text-sm font-medium text-brand-primary">
                        Service Price
                      </label>
                      <input
                        type="text"
                        value={servicePrice}
                        onChange={(e) => setServicePrice(e.target.value)}
                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                        placeholder="160"
                      />
                    </div>
                  </div>

                  {/* Discount Group */}
                  <div className="grid grid-cols-2 gap-4">
                    {/* Discount Percentage */}
                    <div>
                      <label className="block mb-2 text-sm font-medium text-brand-primary">
                        Discount %
                      </label>
                      <input
                        type="text"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        onBlur={calculateDiscountedPrice}
                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                        placeholder="0"
                      />
                    </div>

                    {/* Discounted Price */}
                    <div>
                      <label className="block mb-2 text-sm font-medium text-brand-primary">
                        Final Price
                      </label>
                      <input
                        type="text"
                        value={discountedPrice}
                        readOnly
                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Column - Descriptions */}
              <div className="bg-white rounded-lg shadow-lg p-6">
                <div className="space-y-6">
                  {/* Short Description */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                      Short Description
                    </label>
                    <textarea
                      value={shortDescription}
                      onChange={handleShortDescriptionChange}
                      className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary min-h-[100px]"
                      placeholder="Brief description"
                      maxLength={maxlenghtShort}
                    />
                    <div className="text-right text-sm text-gray-500 mt-1">
                      {shortDescription.length}/{maxlenghtShort}
                    </div>
                  </div>

                  {/* Full Details */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                      Full Details
                    </label>
                    <textarea
                      value={fullDetails}
                      onChange={handleFullDetailsChange}
                      className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary min-h-[200px]"
                      placeholder="Complete service details"
                      maxLength={maxLength}
                    />
                    <div className="text-right text-sm text-gray-500 mt-1">
                      {fullDetails.length}/{maxLength}
                    </div>
                  </div>

                  {/* Duration */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                      Duration (hours:minutes)
                    </label>
                    <input
                      type="text"
                      value={styleDuration}
                      onChange={handleDurationChange}
                      placeholder="1:00"
                      pattern="^[1-9]:[0-5][0-9]$"
                      className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                    />
                  </div>
                </div>
              </div>

              {/* Third Column - Image Upload */}
              <div className="bg-white rounded-lg shadow-lg p-6">
                <div {...getRootProps()} className="h-full">
                <input {...getInputProps()} />
                  <div className="h-full flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer hover:border-brand-primary transition-colors">
                    {uploadedFile ? (
                      <div className="w-full h-full relative">
                      <img
                        src={URL.createObjectURL(uploadedFile)}
                          alt="Preview"
                          className="w-full h-full object-cover rounded-lg"
                      />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setUploadedFile(null);
                          }}
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ) : (
                        <div className="text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 48 48"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            />
                          </svg>
                          <div className="mt-4">
                            <span className="text-sm font-medium text-brand-primary">
                              Drop an image here, or click to select
                            </span>
                          </div>
                          <p className="mt-1 text-xs text-gray-500">
                            PNG, JPG up to 10MB
                          </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Calendar and Time Selection - Full Width */}
              <div className="col-span-3 grid md:grid-cols-2 gap-8 bg-white rounded-lg shadow-lg p-6">
                {/* Calendar Section */}
                <div className="border-r pr-6">
                  <div className="block mb-4 text-lg font-medium text-brand-primary">
                    Select Available Dates
                  </div>
                  <Calendar
                    onChange={handleDateChange}
                    selectRange={true}
                    minDate={new Date()}
                    selectedDates={selectedDates}
                  />
                </div>

                {/* Time Selection Section */}
                <div className="pl-6">
                  <div className="block mb-4 text-lg font-medium text-brand-primary">
                    Assign Times to Dates
                  </div>

                  {selectedDates.length === 0 ? (
                    <div className="flex items-center justify-center h-48 bg-gray-50 rounded-lg">
                      <p className="text-gray-500 text-center">
                        Please select dates from the calendar to assign times
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-6 max-h-[500px] overflow-y-auto pr-4">
                      {selectedDates.map((date) => (
                        <div key={date} className="bg-gray-50 rounded-lg p-4">
                          <h3 className="font-medium text-brand-primary mb-3">
                            {format(new Date(date), 'MMMM d, yyyy')}
                          </h3>

                          <div className="flex gap-2 mb-3">
                            <select
                              value={`${selectedHour}:${selectedMinute}`}
                              onChange={(e) => {
                                const [hour, minute] = e.target.value.split(':');
                                setSelectedHour(hour);
                                setSelectedMinute(minute);
                              }}
                            className="flex-1 p-2 text-sm bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                          >
                            <option value="">Select a time</option>
                            {availableTimeSlots.map((slot) => (
                              <option key={slot} value={slot}>
                                {slot}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            onClick={() => handleAddTime(date)}
                            className="px-4 py-2 bg-brand-primary text-white rounded-lg hover:bg-brand-secondary transition-colors duration-200 text-sm font-medium"
                          >
                            Add Time
                          </button>
                        </div>

                        <div className="mt-3">
                          {!dateTimeMapping[date] || dateTimeMapping[date]?.length === 0 ? (
                            <p className="text-sm text-gray-500 italic">
                              No times selected for this date
                            </p>
                          ) : (
                            <div className="flex flex-wrap gap-2">
                              {dateTimeMapping[date]?.map((time) => (
                                <div
                                  key={time}
                                  className="inline-flex items-center gap-2 bg-white px-3 py-1.5 rounded-full border border-gray-200 shadow-sm"
                                >
                                  <span className="text-sm text-gray-700">{time}</span>
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveTime(date, time)}
                                    className="text-gray-400 hover:text-red-500 transition-colors duration-200"
                                  >
                                    <svg
                                      className="w-4 h-4"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    </div>
                  )}
                </div>
              </div>
            </div>


            {/* Submit and Cancel Buttons */}
        <div className="flex justify-between sm:mt-6 mt-7">
              <div className="flex justify-between w-full gap-4">
            <button
              type="button"
                  className="py-3 px-16 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none"
                  onClick={() => navigate('/admin/services')}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-16 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-brand-primary rounded-lg hover:bg-brand-secondary focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                  disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </div>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  );
};

export default ServiceInfo;
