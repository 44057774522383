import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { fetchServiceTypes } from "../../redux/reducers/serviceTypeSlice";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Calendar from "../../components/Calendar/Calendar";
import { api } from "../../api";
import Loading from "../resuseables/Loading";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

interface Entry {
    styleId: number;
    serviceName: string;
    style: string;
    styleShortDescription: string;
    styleFullDetailsOfService: string;
    stylePrice: string;
    styleImage: File | null;
    styleDuration: string;
    styleDeposit: string | number;
    discount: number;
    value: number;
    dayTimeMapping: { [key: string]: string[] };
    createdAt: string;
}

interface UpdateFormProps {
    style: Entry;
    onUpdate: (styleId: number, updatedStyleData: FormData) => Promise<void>;
    onCancel: () => void;
}

const UpdateServiceInfoMain: React.FC<UpdateFormProps> = ({
    style,
    onUpdate,
    onCancel,
}) => {

    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
    const services = useSelector((state: RootState) => state.serviceTypes.services);

    const [selectedService, setSelectedService] = useState(style?.styleId || 0);
    const [selectedServiceName, setSelectedServiceName] = useState(style?.serviceName || "");
    const [styles, setStyles] = useState(style?.style || "");
    const [deposit, setDeposit] = useState(style?.styleDeposit?.toString() || "");
    const [servicePrice, setServicePrice] = useState(style?.stylePrice || "");
    const [discount, setDiscount] = useState(style?.discount?.toString() || "");
    const [discountedPrice, setDiscountedPrice] = useState(style?.value?.toString() || "");
    const [shortDescription, setShortDescription] = useState(style?.styleShortDescription || "");
    const [fullDetails, setFullDetails] = useState(style?.styleFullDetailsOfService || "");
    const [styleDuration, setStyleDuration] = useState(style?.styleDuration || '1:00');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const [selectedHour, setSelectedHour] = useState("00");
    const [selectedMinute, setSelectedMinute] = useState("00");
    const [isLoading, setIsLoading] = useState(false);
    const [existingImageUrl, setExistingImageUrl] = useState<string>("");
    const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
    const [selectedDates, setSelectedDates] = useState<string[]>(
        style?.dayTimeMapping ? Object.keys(style.dayTimeMapping) : []
    );
    const [dateTimeMapping, setDateTimeMapping] = useState<{
        [key: string]: string[];
    }>(() => {
        if (!style?.dayTimeMapping) return {};

        // Convert string times to arrays
        const initialMapping: { [key: string]: string[] } = {};
        Object.entries(style.dayTimeMapping).forEach(([date, times]) => {
            initialMapping[date] = typeof times === 'string' ? [times] : times;
        });
        return initialMapping;
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
        onDrop: (acceptedFiles) => {
            setUploadedFile(acceptedFiles[0]);
        },
    });

    // Add these constants at the top with your other state variables
    const maxlenghtShort = 150; // Or whatever max length you want for short description
    const maxLength = 500; // Or whatever max length you want for full details

    // Add these handler functions before the handleSubmit function
    const handleShortDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length <= maxlenghtShort) {
            setShortDescription(e.target.value);
        }
    };

    const handleFullDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length <= maxLength) {
            setFullDetails(e.target.value);
        }
    };

    useEffect(() => {
        dispatch(fetchServiceTypes());
    }, [dispatch]);

    useEffect(() => {
        if (style && services.length > 0) {
            setSelectedServiceName(style.serviceName || "");

            const foundService = services.find(
                (service) => service.serviceName === style.serviceName
            );
            if (foundService) {
                setSelectedService(foundService.serviceId);
            }

            setStyles(style.style || "");
            setDeposit(style.styleDeposit?.toString() || "");
            setServicePrice(style.stylePrice || "");
            setDiscount(style.discount?.toString() || "");
            setDiscountedPrice(style.value?.toString() || "");
            setShortDescription(style.styleShortDescription || "");
            setFullDetails(style.styleFullDetailsOfService || "");
            setExistingImageUrl(typeof style.styleImage === 'string' ? style.styleImage : "");
            updateAvailableTimeSlots(style.styleDuration || '1:00');
        
        }
    }, [style, services]);

    useEffect(() => {
        if (style && style.dayTimeMapping) {
            // Convert the string-based mapping to arrays
            const initialMapping: { [key: string]: string[] } = {};
            Object.entries(style.dayTimeMapping).forEach(([date, times]) => {
                initialMapping[date] = typeof times === 'string' ? [times] : times;
            });
            setDateTimeMapping(initialMapping);
            setSelectedDates(Object.keys(initialMapping));
        }
    }, [style]);

    const handleDateChange = (date: Date | Date[]) => {
        if (Array.isArray(date)) {
            const filteredDates = date.filter((d) => d >= new Date());
            const formattedDates = filteredDates.map((d) => format(d, "yyyy-MM-dd"));
            setSelectedDates(formattedDates);

            const newMapping = { ...dateTimeMapping };
            formattedDates.forEach(date => {
                if (!newMapping[date]) {
                    newMapping[date] = [];
                }
            });

            setDateTimeMapping(newMapping);
        } else {
            if (date < new Date()) {
                toast.error("Cannot select previous dates");
            } else {
                const formattedDate = format(date, "yyyy-MM-dd");
                setSelectedDates([formattedDate]);
                setDateTimeMapping({ [formattedDate]: [] });
            }
        }
    };

    const updateAvailableTimeSlots = (duration: string) => {
        const [hours, minutes] = duration.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) return;

        const durationInMinutes = hours * 60 + minutes;
        const slots = [];
        let currentTime = new Date(2023, 0, 1, 8, 0);
        const endTime = new Date(2023, 0, 1, 19, 0);

        while (currentTime < endTime) {
            slots.push(currentTime.toTimeString().slice(0, 5));
            currentTime.setMinutes(currentTime.getMinutes() + durationInMinutes);
        }

        setAvailableTimeSlots(slots);
    };

    const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setStyleDuration(input);

        if (/^[1-9]:[0-5][0-9]$/.test(input)) {
            updateAvailableTimeSlots(input);
        }
    };

    const handleAddTime = (selectedDate: string) => {
        const selectedTime = `${selectedHour}:${selectedMinute}`;

        if (!availableTimeSlots.includes(selectedTime)) {
            toast.error('Please select a valid time slot based on the style duration.');
            return;
        }

        if (dateTimeMapping[selectedDate]?.includes(selectedTime)) {
            toast.error('This time slot has already been added for this date.');
            return;
        }

        setDateTimeMapping(prev => ({
            ...prev,
            [selectedDate]: [...(prev[selectedDate] || []), selectedTime].sort()
        }));
    };

    const handleRemoveTime = (date: string, time: string) => {
        setDateTimeMapping(prev => {
            const newMapping = { ...prev };
            newMapping[date] = newMapping[date].filter(t => t !== time);

            // If no times left for this date, remove the date entry
            if (newMapping[date].length === 0) {
                delete newMapping[date];
                setSelectedDates(dates => dates.filter(d => d !== date));
            }

            return newMapping;
        });
    };

    const calculateDiscountedPrice = () => {
        const price = parseFloat(servicePrice);
        const discountPercentage = parseFloat(discount);
        if (!isNaN(price) && !isNaN(discountPercentage)) {
            const discountAmount = (price * discountPercentage) / 100;
            const discountedPrice = price - discountAmount;
            setDiscountedPrice(discountedPrice.toString());
        }
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
        formData.append("serviceId", String(selectedService));
        formData.append("serviceName", selectedServiceName);
        formData.append("style", styles);
        formData.append("styleShortDescription", shortDescription);
        formData.append("styleFullDetailsOfService", fullDetails);
        formData.append("stylePrice", servicePrice);

        // Handle image upload
        if (uploadedFile) {
            formData.append("styleImage", uploadedFile);
        } else if (existingImageUrl) {
            const response = await fetch(existingImageUrl);
            const blob = await response.blob();
            const fileName = existingImageUrl.split('/').pop() || 'image.jpg';
            const file = new File([blob], fileName, { type: blob.type });
            formData.append("styleImage", file);
        } else {
            throw new Error("Please upload an image for the style.");
        }

        formData.append('styleDuration', styleDuration);
        formData.append("styleDeposit", deposit);
        formData.append("discount", discount);
        formData.append("value", discountedPrice);

        // Format the dates and times for submission
        const availableDates = Object.keys(dateTimeMapping);
        const formattedTimes = availableDates.map(date =>
            dateTimeMapping[date].join(',')
        );

        formData.append('availableDays', availableDates.join(','));
        formData.append('availableTimes', formattedTimes.join('|'));

        try {
            const response = await api.put(
                `/api/styles/update/${style.styleId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to update style");
            }

            toast.success("Style updated successfully!");
            onUpdate(style.styleId, formData);
            navigate('/admin/services');
        } catch (err) {
            toast.error("Failed to update style. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full min-h-screen bg-gray-50 py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-2xl font-semibold text-brand-primary mb-8">
                    {style ? "Update Style" : "Add Style"}
                </h2>

                <form onSubmit={handleSubmit} className="space-y-8">
                    <div className="grid md:grid-cols-3 gap-8">
                        {/* First Column - Basic Info */}
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <div className="space-y-6">
                                {/* Service Name */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                                        Service Name
                                    </label>
                                    <select
                                        value={selectedService}
                                        onChange={(e) => {
                                            const serviceId = parseInt(e.target.value, 10);
                                            setSelectedService(serviceId);
                                            const service = services.find(
                                                (service) => service.serviceId === serviceId
                                            );
                                            setSelectedServiceName(service ? service.serviceName : '');
                                        }}
                                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                        disabled={!!style}
                                    >
                                        <option value="">Select a service</option>
                                        {services.map((service) => (
                                            <option key={service.serviceId} value={service.serviceId}>
                                                {service.serviceName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Style Name */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                                        Style Name
                                    </label>
                                    <input
                                        type="text"
                                        value={styles}
                                        onChange={(e) => setStyles(e.target.value)}
                                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                        placeholder="Medium"
                                    />
                                </div>

                                {/* Price Group */}
                                <div className="grid grid-cols-2 gap-4">
                                    {/* Deposit */}
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-brand-primary">
                                            Deposit
                                        </label>
                                        <input
                                            type="text"
                                            value={deposit}
                                            onChange={(e) => setDeposit(e.target.value)}
                                            className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                            placeholder="20"
                                        />
                                    </div>

                                    {/* Service Price */}
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-brand-primary">
                                            Service Price
                                        </label>
                                        <input
                                            type="text"
                                            value={servicePrice}
                                            onChange={(e) => setServicePrice(e.target.value)}
                                            className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                            placeholder="160"
                                        />
                                    </div>
                                </div>

                                {/* Discount Group */}
                                <div className="grid grid-cols-2 gap-4">
                                    {/* Discount Percentage */}
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-brand-primary">
                                            Discount %
                                        </label>
                                        <input
                                            type="text"
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                            onBlur={calculateDiscountedPrice}
                                            className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                            placeholder="0"
                                        />
                                    </div>

                                    {/* Final Price */}
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-brand-primary">
                                            Final Price
                                        </label>
                                        <input
                                            type="text"
                                            value={discountedPrice}
                                            readOnly
                                            className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg"
                                            placeholder="0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Second Column - Descriptions */}
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <div className="space-y-6">
                                {/* Short Description */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                                        Short Description
                                    </label>
                                    <textarea
                                        value={shortDescription}
                                        onChange={handleShortDescriptionChange}
                                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary min-h-[100px]"
                                        placeholder="Brief description"
                                        maxLength={maxlenghtShort}
                                    />
                                    <div className="text-right text-sm text-gray-500 mt-1">
                                        {shortDescription.length}/{maxlenghtShort}
                                    </div>
                                </div>

                                {/* Full Details */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                                        Full Details
                                    </label>
                                    <textarea
                                        value={fullDetails}
                                        onChange={handleFullDetailsChange}
                                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary min-h-[200px]"
                                        placeholder="Complete service details"
                                        maxLength={maxLength}
                                    />
                                    <div className="text-right text-sm text-gray-500 mt-1">
                                        {fullDetails.length}/{maxLength}
                                    </div>
                                </div>

                                {/* Duration */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-brand-primary">
                                        Duration (hours:minutes)
                                    </label>
                                    <input
                                        type="text"
                                        value={styleDuration}
                                        onChange={handleDurationChange}
                                        placeholder="1:00"
                                        pattern="^[1-9]:[0-5][0-9]$"
                                        className="w-full p-3 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-brand-primary focus:border-brand-primary"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Third Column - Image Upload */}
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <div {...getRootProps()} className="h-full">
                                <input {...getInputProps()} />
                                <div className="h-full flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer hover:border-brand-primary transition-colors">
                                    {uploadedFile ? (
                                        <div className="w-full h-full relative">
                                            <img
                                                src={URL.createObjectURL(uploadedFile)}
                                                alt="Preview"
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setUploadedFile(null);
                                                }}
                                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                                            >
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    ) : (
                                            <div className="text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 48 48"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    />
                                                </svg>
                                                <div className="mt-4">
                                                    <span className="text-sm font-medium text-brand-primary">
                                                        Drop an image here, or click to select
                                                    </span>
                                                </div>
                                                <p className="mt-1 text-xs text-gray-500">
                                                    PNG, JPG up to 10MB
                                                </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Calendar and Time Selection - Full Width */}
                        <div className="col-span-3 grid md:grid-cols-2 gap-8 bg-white rounded-lg shadow-lg p-6">
                            {/* Calendar Section */}
                            {/* Calendar Section */}
                            <div className="border-r pr-6">
                                <div className="block mb-4 text-lg font-medium text-brand-primary">
                                    Select Available Dates
                                    {Object.keys(dateTimeMapping).length > 0 && (
                                        <span className="text-sm text-gray-500 ml-2">
                                            ({Object.keys(dateTimeMapping).length} dates selected)
                                        </span>
                                    )}
                                </div>
                                <Calendar
                                    onChange={handleDateChange}
                                    selectRange={true}
                                    minDate={new Date()}
                                    selectedDates={selectedDates}
                                    value={selectedDates.map(date => new Date(date))}
                                    tileClassName={({ date }) => {
                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                        return dateTimeMapping[formattedDate] ? 'has-times' : '';
                                    }}
                                />
                            </div>

                            {/* Time Selection Section */}
                            {/* Time Selection Section */}
                            <div className="pl-6">
                                <div className="block mb-4 text-lg font-medium text-brand-primary">
                                    Assign Times to Dates
                                </div>

                                {selectedDates.length === 0 ? (
                                    <div className="flex items-center justify-center h-48 bg-gray-50 rounded-lg">
                                        <p className="text-gray-500 text-center">
                                            Please select dates from the calendar to assign times
                                        </p>
                                    </div>
                                ) : (
                                    <div className="space-y-6 max-h-[500px] overflow-y-auto pr-4">
                                        {selectedDates.map((date) => (
                                            <div key={date} className="bg-gray-50 rounded-lg p-4">
                                                <h3 className="font-medium text-brand-primary mb-3">
                                                    {format(new Date(date), 'MMMM d, yyyy')}
                                                    {dateTimeMapping[date]?.length > 0 && (
                                                        <span className="text-sm text-gray-500 ml-2">
                                                            ({dateTimeMapping[date].length} times)
                                                        </span>
                                                    )}
                                                </h3>

                                                <div className="flex gap-2 mb-3">
                                                    <select
                                                        value={`${selectedHour}:${selectedMinute}`}
                                                        onChange={(e) => {
                                                            const [hour, minute] = e.target.value.split(':');
                                                            setSelectedHour(hour);
                                                            setSelectedMinute(minute);
                                                        }}
                                                        className="flex-1 p-2 text-sm bg-white border border-gray-200 rounded-lg"
                                                    >
                                                        <option value="">Select a time</option>
                                                        {availableTimeSlots.map((slot) => (
                                                            <option key={slot} value={slot}
                                                                disabled={dateTimeMapping[date]?.includes(slot)}
                                                            >
                                                                {slot} {dateTimeMapping[date]?.includes(slot) ? '(Selected)' : ''}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleAddTime(date)}
                                                        className="px-4 py-2 bg-brand-primary text-white rounded-lg"
                                                    >
                                                        Add Time
                                                    </button>
                                                </div>

                                                {/* Display selected times */}
                                                <div className="mt-3">
                                                    {!dateTimeMapping[date] || dateTimeMapping[date]?.length === 0 ? (
                                                        <p className="text-sm text-gray-500 italic">
                                                            No times selected for this date
                                                        </p>
                                                    ) : (
                                                        <div className="flex flex-wrap gap-2">
                                                            {dateTimeMapping[date]?.map((time) => (
                                                                <div
                                                                    key={time}
                                                                    className="inline-flex items-center gap-2 bg-white px-3 py-1.5 rounded-full border border-gray-200 shadow-sm"
                                                                >
                                                                    <span className="text-sm text-gray-700">{time}</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveTime(date, time)}
                                                                        className="text-gray-400 hover:text-red-500"
                                                                    >
                                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div className="flex justify-between sm:mt-6 mt-7">
                        <div className="flex justify-between w-full gap-4">
                            <button
                                type="button"
                                className="py-3 px-16 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none"
                                onClick={() => navigate('/admin/services')}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-16 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-brand-primary rounded-lg hover:bg-brand-secondary focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center">
                                        <svg
                                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        Loading...
                                    </div>
                                ) : (
                                        'Submit'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateServiceInfoMain;
