import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    roles: [];
}

const UsersTable = () => {
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 8;
    const totalPages = Math.ceil(users.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = users.slice(startIndex, endIndex);

    const headers = ["SN", "First Name", "Last Name", "Email", "Phone", "Role"];

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            setTableLoading(true);
            const response = await api.get("api/users", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            setUsers(response.data);
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch users");
        } finally {
            setTableLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <section className="px-4 mx-auto w-full">
            <div className="mt-6 md:flex md:items-center sm:justify-between">
                <p className="bg-white text-2xl">List of Users</p>
            </div>

            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border md:rounded-lg">
                            {tableLoading ? (
                                <div className="flex justify-center items-center h-64">
                                    <PropagateLoader
                                        color="#FF0000"
                                        loading={tableLoading}
                                        size={15}
                                    />
                                </div>
                            ) : (
                                <>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {headers.map((header, index) => (
                                                    <th
                                                        key={index}
                                                        className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                                                    >
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-brand-primary divide-y divide-gray-200">
                                            {currentData.map((users, index) => (
                                                <tr key={users.id}>
                                                    <td className="px-4 py-4 text-sm text-white">
                                                        {startIndex + index + 1}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white break-words whitespace-normal max-w-xs">
                                                        {users.firstName}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white">
                                                        {users.lastName}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white">
                                                        {users.email}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white">
                                                        {users.mobilePhone}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white">
                                                        {users.roles
                                                            .map((role: any) => role.name)
                                                            .join(", ")}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="flex items-center justify-end mt-6 gap-2">
                                        <button
                                            onClick={() =>
                                                handlePageChange(Math.max(1, currentPage - 1))
                                            }
                                            disabled={currentPage === 1}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Prev
                                        </button>

                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`px-4 py-2 text-sm font-medium ${currentPage === index + 1
                                                        ? "text-blue-600 bg-blue-100"
                                                        : "text-gray-700 bg-white"
                                                    } border border-gray-300 rounded-md hover:bg-gray-50`}
                                                disabled={currentPage === index + 1}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}

                                        <button
                                            onClick={() =>
                                                handlePageChange(Math.min(totalPages, currentPage + 1))
                                            }
                                            disabled={currentPage >= totalPages}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UsersTable;
