import React from 'react'
import UsersTable from './UsersTable'

const Users = () => {
    return (
        <div>
            <UsersTable />
        </div>
    )
}

export default Users